<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">
        {{ $t("document_list.signing_progress") }}
      </v-card-title>

      <!-- 🔹 Alerta como advertencia -->
      <v-alert type="warning" dense class="mx-4" color="amber darken-2" dark>
        {{ $t("document_list.do_not_close_browser") }}
      </v-alert>

      <v-card-text>
        <v-progress-linear
          :value="progress"
          color="primary"
          height="20"
          class="mb-3"
        >
          <span :style="{ color: textColor }">
            {{ progress }}%
          </span>
        </v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    signedDocs: Number,
    totalDocs: Number,
  },
  mounted() {
      window.addEventListener("beforeunload", this.preventClose);
    },
  beforeDestroy() {
      window.removeEventListener("beforeunload", this.preventClose);
    },
  computed: {
    // 🔹 Calcular el porcentaje redondeado
    progress() {
      return this.totalDocs > 0 ? Math.round((this.signedDocs / this.totalDocs) * 100) : 0;
    },
    // 🔹 Cambiar el color del texto según el progreso
    textColor() {
      return this.progress > 50 ? "white" : "black";
    }
  },
  methods: {
      preventClose(e) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
  watch: {
    // 🔹 Cerrar el diálogo automáticamente cuando llegue al 100%
    progress(newProgress) {
      if (newProgress === 100) {
        this.$emit("close-dialog");
      }
    }
  }
};
</script>
