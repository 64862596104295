<template>
	<v-container class="py-8">
		<div class="mb-4 action-summary-area" :class="[
			$vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : '',
			this.completeBatchProcess ? 'summary-visible' : '',
		]">
			<div class="d-flex justify-space-between mb-4">
				<div class="d-flex flex-grow-1">
					<v-text-field prepend-inner-icon="mdi-magnify"
						:placeholder="$t('document_list.search_field_text')"
						solo clearable flat outlined dense hide-details
						v-model="searchKeyWord" />
					<v-btn class="ml-2 primary" height="40" dark @click="loadSearchList()">
						{{ $t("document_list.search") }}
					</v-btn>
				</div>
				<v-btn class="ml-6 secondary" fab small dark depressed @click="showAdvanceSearchDialog()">
					<span class="filter-count primary" v-if="searchFilterCount > 0">
						{{ searchFilterCount }}
					</span>
					<v-icon>mdi-tune</v-icon>
				</v-btn>
			</div>

			<div v-if="completeBatchProcess || rejectedDocCount > 0"
				class="mb-2 final-doc-count font-weight-bold py-3 px-4">
				<div v-if="completeBatchProcess">
					{{ $t("document_list.signed_documents") }}:
					<span class="ml-1">{{ successDocs }}</span>
				</div>
				<div v-if="rejectedDocCount > 0">
					{{ $t("document_list.rejected_documents") }}:
					<span class="ml-1">{{ rejectedDocCount }}</span>
				</div>
			</div>

			<div v-if="!list" class="mb-4 pending-doc-count font-weight-bold py-3 px-4">
				<div>
					{{ $t("document_list.documents_pending_signature") }}:
					<span class="ml-1">{{ numReqPending }}</span>
				</div>
				<div v-if="docList.length > 0">
					{{ $t("document_list.documents_selected_for_multiple_signature") }}:
					<span class="ml-1">{{ selectedDocs.length }}</span>
				</div>
			</div>

			<v-alert
				v-if="allDocsSigned"
				type="success"
				color="green"
				dense
				class="mb-4 text-white"
			>
				{{ $t("document_list.all_documents_signed") }}
			</v-alert>
		</div>

		<v-card outlined class="document-list-card-wrapper">
			<v-data-table
				v-model="selectedDocs"
				class="document-list"
				:headers="headers"
				:items="docList"
				item-key="id"
				:show-select="shouldShowCheckboxes"
				:hide-default-footer="false"
				:mobile-breakpoint="0"
				item-class="status"
				:items-per-page="10"
				@toggle-select-all="checkSelectAll"
				:footer-props="{ 'items-per-page-options': [10] }"
			>
				<template v-slot:[`item.signatureTransaction.description`]="{ item }">
					<v-btn text color="primary" @click="handleLinkClick(item.id, item.signatureTransaction.id, item.status)">
						{{ item.signatureTransaction.description || "-" }}
					</v-btn>
				</template>

				<template v-slot:[`item.dateSent`]="{ item }">
					{{ item.dateSent && item.dateSent !== "null" ? moment(item.dateSent).format("YYYY-MM-DD") : "-" }}
				</template>

				<template v-slot:[`item.signatureTransaction.validityDate`]="{ item }">
					{{
						item.signatureTransaction.validityDate &&
						item.signatureTransaction.validityDate !== "null"
							? moment(item.signatureTransaction.validityDate).format("YYYY-MM-DD")
							: "-"
					}}
				</template>

                <!-- ✅ Nuevo slot para mostrar íconos de estado con ícono de descarga si corresponde -->
                <template v-if="list" v-slot:[`item.status`]="{ item }">
                  <v-icon
                    v-if="item.status === 'NOTIFIED'"
                    color="orange"
                    title="Pendiente de firma"
                    class="mr-1"
                  >
                    mdi-clock-outline
                  </v-icon>
                  <v-icon
                    v-else-if="item.status === 'SIGNED'"
                    color="green"
                    title="Firmado"
                    class="mr-1"
                  >
                    mdi-check-circle-outline
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    title="Estado desconocido"
                    class="mr-1"
                  >
                    mdi-help-circle-outline
                  </v-icon>

                  <!-- 🎯 Icono de descarga si agregatedStatus === 3 -->
                  <v-icon
                    v-if="item.signatureTransaction.status === 3"
                    color="success"
                    title="Documento disponible para descarga"
                    @click.stop="downloadDocument(item)"
                  >
                    mdi-download
                  </v-icon>
                </template>

			</v-data-table>
		</v-card>

		<div class="text-center pt-4 document-list-pagination-wrapper"></div>

		<!-- Footer de acciones -->
		<div class="footer-message">
			<v-container v-if="!allDocsSigned" class="px-2 fill-height">
				<v-row>
					<div class="col-md-8 col-xs-12 text-left terms">
						<v-list>
							<v-list-item>
								<template v-slot:default="{ active }">
									<v-list-item-action>
										<v-checkbox
											dark color="#F36E21"
											v-model="terms_accepted"
											:input-value="active"
											:disabled="selectedDocs.length < 1"
										></v-checkbox>
									</v-list-item-action>
									<v-list-item-content class="white--text">
										{{ $t("document_list.read_and_understand_the_documents") }}
									</v-list-item-content>
								</template>
							</v-list-item>
						</v-list>
					</div>

					<div class="row">
						<div class="col-md-6 col-6 sign pl-0">
							<v-btn large color="#47D764" block dark
								:disabled="!terms_accepted"
								class="sign-reject-btn"
								:loading="loading"
								@click="signDocument()"
							>
								<v-icon class="mr-2">mdi-pencil-box-outline</v-icon>
								{{ $t("multiple_sign") }}
							</v-btn>
						</div>
						<div class="col-md-6 col-6 reject pr-0">
							<!-- Botón de revisión deshabilitado -->
						</div>
					</div>
				</v-row>
			</v-container>
		</div>

		<!-- Modales y componentes adicionales -->
		<doc-list-advance-search :dialog="advanceSearchDialog" :errorMsg="errorMsg" :error="error"
			@submitFilters="submitFilters" />

		<sign-confirm-pad-modal :dialog="padDialog" :errorMsg="errorMsg" :error="error"
			@submitSignPad="submitSignPad" />

		<certificate-pin-modal :dialog="certificatePinCodeDialog" :errorMsg="errorMsg" :error="error"
			@submitCertificatePin="submitCertificatePin" />

		<sign-confirm-otp-modal :dialog="otpDialog" :errorMsg="errorMsg" :error="error"
			@submitOtpCode="submitOtpCode" @resendOtpCode="sendOtpCode" />

		<signing-progress-modal :dialog="batchSigning" :signedDocs="successDocs" :totalDocs="totalSelectedDocs" />

		<DefaultLoading v-if="loading" :loading="loading" />
	</v-container>
</template>

<script>
import DocListAdvanceSearch from "../components/shared/DocListAdvanceSearch.component";
import SignConfirmPadModal from "../components/shared/SignConfirmPadModal.component";
import SignConfirmOtpModal from "../components/shared/SignConfirmOtpModal.component";
import CertificatePinModal from "../components/shared/CertificatePinModal.component.vue";
import DefaultLoading from "../components/shared/DefaultLoading.component";
import SigningProgressModal from "../components/shared/SigningProgressModal.component.vue";
import { SignaturesService } from "../services/signatures-service";
import moment from "moment";
import UploadService from "@/services/upload-service";
import FormsService from "@/services/forms-service";
import { mapState, mapGetters } from 'vuex';

export default {
	name: "document-list",
	components: {
		DocListAdvanceSearch,
		SignConfirmPadModal,
		SignConfirmOtpModal,
		CertificatePinModal,
		DefaultLoading,
		SigningProgressModal
	},
	data: function () {
		return {
			otpDialog: false,
			otpCode: "",
            batchSigning: false,
			loading: false,
			padDialog: false,
			certificatePinCodeDialog: false,
			certificatePinCode: null,
			signaturePadImage: null,
			errorMsg: null,
			error: null,
			terms_accepted: "",
			searchFilters: {},
			selectedDocs: [],
			advanceSearchDialog: false,
			totalSelectedDocs: 0,
			successDocs: 0,
			failDocs: 0,
			invalidPin: false,
			page: 1,
			totalPages: 0,
			itemsPerPage: 10,
			docList: [],
			pageNumber: 1,
			numReqPending: 0,
			searchKeyWord: null,
			completeBatchProcess: false,
			prevRoute: "-",

			pendingStatus: 20,
			searchFilterCount: 0,
			padStatus: false,
			rejectedDocCount: 0,
			searchUrlParams: {},
		};
	},
	computed: {
        shouldShowCheckboxes() {
              return localStorage.getItem("user") === "true";
            },

        allDocsSigned() {
            console.log("TODOS FIRMADOS?: " + this.docList.every(doc => doc.status === 'SIGNED'));
            return this.docList.length > 0 && this.docList.every(doc => doc.status === 'SIGNED');
        },



		headers() {
			return [
				{
					text: this.$t("document_list.document"),
					value: "signatureTransaction.description",
					sortable: false,
				},
				{
					text: this.$t("document_list.send"),
					value: "dateSent",
					sortable: false,
				},
/*				{
					text: this.$t("document_list.expiration"),
					value: "signatureTransaction.validityDate",
					sortable: false,
				},
				*/
				{
                    text: this.$t("document_list.status"),
                    value: "status",
                    sortable: false,
                },
			];
		},
		prevRoutePath() {
			return this.prevRoute ? this.prevRoute.path : "/";
		},
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		},
		currentRoute() {
			return this.$route;
		},

        ...mapState({
            SignRequestID: state => state.common.requestId,
        }),

        ...mapGetters('attachments', [
            'hasAttachments', 'getTransactionApiToken'
        ]),
        hasSignerForm() {
            return this.$store.getters['forms/hasSignerForm'];
        },
        hasPendingSignerForm() {
            return this.$store.getters['forms/hasPendingSignerForm'];
        }

	},
	watch: {
		successDocs() {
			if (this.successDocs > 0) {
				this.checkBatchProcessStatus();
			}
		},
		failDocs() {
			if (this.failDocs > 0) {
				this.checkBatchProcessStatus();
			}
		},
	},
	methods: {
		downloadDocument(item) {
            console.log("ItemId: " + item.id);

//			if (this.transactionId) {
//				this.loadingDownload = true;
				SignaturesService.getCertificateSignatureRequestDocument(
					item.id, item.apiToken,this.AuthId
				)
					.then((response) => {
						var file = new Blob([response.data], { type: "application/pdf" });
						var fileURL = URL.createObjectURL(file);
						var a = document.createElement("a");
						a.href = fileURL;
						a.download = item.signatureTransaction.document.filename;
						a.click();
						window.URL.revokeObjectURL(fileURL);
						this.loadingDownload = false;
					})
					.catch((error) => {
						console.log(error);
						this.loadingDownload = false;
					});
//			}
		},

        setApiToken(){
            let token;
            console.log("List: " + this.list);
            console.log("User: " + this.user);
            if (this.user){
                token = localStorage.getItem("auth_user_token");
                }

            if (this.list){
                token = localStorage.getItem("multiple_token");
                }
            console.log ("Cargando docs con: " + token);
            return token;
        },


        findTokenFromCheckList() {
            // Verificar si this.selectedDocs existe y es un array válido
            if (!Array.isArray(this.selectedDocs) || this.selectedDocs.length === 0) {
                console.error("❌ ERROR: this.selectedDocs no es un array o está vacío.");
                return null;
            }

            // Mostrar toda la lista de documentos seleccionados
            console.log("📜 Lista de documentos seleccionados:", this.selectedDocs);

            // Verificar qué ID se está buscando
            console.log("🔍 Buscando ID:", this.requestId, " (Tipo:", typeof this.requestId, ")");

            // Iterar sobre el array para mostrar cada ID
            this.selectedDocs.forEach((item, index) => {
                console.log(`🔎 Elemento ${index + 1}: ID en array = "${item.id}" (Tipo: ${typeof item.id})`);
            });

            // Buscar con más control
            const foundItem = this.selectedDocs.find(item => {
                console.log(`➡️ Comparando "${item.id}" con "${this.requestId}"`);
                return String(item.id).trim() === String(this.requestId).trim();
            });

            // Si no se encuentra, mostrar advertencia y devolver null
            if (!foundItem) {
                console.warn(`⚠️ Advertencia: No se encontró un documento con id = "${this.requestId}".`);
                return null;
            }

            // Si se encontró, mostrar el token antes de devolverlo
            console.log("✅ Documento encontrado:", foundItem);
            console.log("🔑 Token encontrado:", foundItem.apiToken);

            return foundItem.apiToken || null;
        },




        openLocationDialog() {
                    console.log("Dentro de openLocationDialog");
                    if (navigator && navigator.geolocation) {
                        console.log("Geolocalización soportada");
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                this.userLatitude = position.coords.latitude;
                                this.userLongitude = position.coords.longitude;
                                console.log("Latitud: " + this.userLatitude + "longitud: " + this.userLongitude);
//                                this.submitLocation = true;
                            },
                            () => {
                                this.userLatitude = "Unknown";
                                this.userLongitude = "Unknown";
//                                this.submitLocation = true;
                            }
                        );
                    }
                },

		getSubmitData() {
            console.log("Dentro de GetSubmitData");
			let submitData = {
				comments: "",
                location:
					this.userLatitude && this.userLongitude
						? this.userLatitude + ", " + this.userLongitude
						: "",
				lastRefresh: moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ"),
			};




			console.log("Evaluando Request Type dentro de SubmitBatch: " + this.requestType);
			if (this.requestType == "ONETIMEWITHID") {
                console.log("Añadiendo AuthId: " + this.authId);
				submitData["authId"] = this.authId;
			}

			if (this.otpStatus) {
                console.log("Añadiendo OTPCode: " + this.otpCode);
				submitData["otp"] = this.otpCode;
			}

			if (this.padStatus) {
                console.log("Añadiendo padStatus: " + this.padImage);
				submitData["signatureImage"] = this.padImage;
			}

			if (this.requestType == "HUMAN"){
                console.log("Añadiendo PIN: " + this.certificatePinCode);
                submitData["password"] = this.certificatePinCode;
			}

			return submitData;
		},

        async sendOtpCode() {
            if (this.isSendingOtp) {
                console.warn("⚠️ OTP ya solicitado, evitando duplicación...");
                return;
            }

            this.isSendingOtp = true;
            this.error = false;

            console.log("📌 Enviando OTP a la API...");
            this.otpDialog = true;
/*
            let data = {
                comments: "",
                authId: this.authId ?? "",  // Si authId es null o undefined, asigna ""
                location: "",
                lastRefresh: "",
                otp: "",
                signatureImage: "",
            };
*/
            let otpToken = this.setApiToken();
            console.log("Token: " + otpToken);
            try {
                console.log("📌 Ejecutando SignaturesService.sendOtp...");
//                console.log("Token =" + this.setApiToken());
                await SignaturesService.sendMultipleOtp(otpToken);
            } catch (error) {
                console.error("❌ Error en sendOtpCode:", error);
            } finally {
                this.isSendingOtp = false;
            }
        },

        submitDateAndLocation(){
            console.log("dentro de submitDateAndLocation");
            console.log("Lat:", this.userLatitude, "Lon:", this.userLongitude);
            this.lastRefresh = moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ");
            this.location = this.userLatitude && this.userLongitude
                ? this.userLatitude + ", " + this.userLongitude
                : "",
            console.log("📌 Fecha y hora guardadas:", this.lastRefresh);
            console.log("📌 Ubicación guardada:", this.location);
        },

        getSignatureToken() {
        console.log("Dentro de get signature token");
        console.log("TOKEN de usuario: " + localStorage.getItem("auth_user_token"));
            if (localStorage.getItem("auth_user_token")) {
                this.token = localStorage.getItem("auth_user_token");
            } else if (localStorage.getItem("access_token")) {
                this.token = localStorage.getItem("access_token");
            } else {
                this.token = null; // O cualquier valor por defecto
            }
            console.log("Token asignado:", this.token);
        },

		resetProcess() {
			this.otpDialog = false;
			this.otpCode = "";
			this.padDialog = false;
			this.certificatePinCodeDialog = false;
			this.certificatePinCode = null;
			this.signaturePadImage = null;
			this.errorMsg = null;
			this.error = null;
			this.terms_accepted = "";
			this.searchFilters = {};
			this.selectedDocs = [];
			this.advanceSearchDialog = false;
			this.totalSelectedDocs = 0;
			this.invalidPin = false;
			this.docList = [];
			this.totalPages = 0;
			this.pageNumber = 1;
			this.numReqPending = 0;
			this.searchKeyWord = "";
			this.padStatus = false;
			localStorage.setItem("reviewed_list", "");
		},

//Nuevo método
        async getUploadedDocCount($request_id, $request_token, $request_auth_id) {
            try {
                const attachments = await UploadService.getAttachments($request_id, $request_token, $request_auth_id);
                return attachments.data.length;
            } catch (error) {
                console.error('Error in getUploadedDocCount:', error);
                return 0;  // Return default value in case of error
            }
        },
//NUEVA FUNCION DE CALCULO DE VARIABLES Y CARGA DE PDF

		async singleDoc() {
            console.log("dentro de singleDoc");
			this.accessDialog = false;
         // ✅ Guardamos correctamente el Request ID desde localStorage
            await this.$nextTick(); // Asegura que Vue termine de actualizar antes de continuar
            const requestId = localStorage.getItem("request_id");



            if (!requestId) {
                console.error("❌ ERROR: No Request ID found en localStorage");
                return;
            }

            console.log("✅ Request ID encontrado:", requestId);

			this.AuthID = localStorage.getItem("auth_id");
			this.SignReguestID = localStorage.getItem("request_id");
			const hasAssociation = localStorage.getItem("isCustomizedUser") === "true";
			await this.loadDocumentData(requestId, this.AuthID);

			let docCount = await this.getUploadedDocCount(this.SignRequestID, this.Token, this.AuthID);
			docCount = Number(docCount);

			console.log(docCount);

			if (isNaN(docCount)) {
				console.error('docCount is not a number:', docCount);
				docCount = 0;  // Set default to 0 if NaN
			}
			console.log('status = ' + this.hasAttachments + this.hasSignerForm + this.hasPendingSignerForm);

			if (this.hasAttachments && docCount == 0  && this.hasPendingSignerForm && this.hasSignerForm) {
                let queryParams = {};

                // Agregar token solo si this.accessToken tiene valor
                if (this.accessToken) {
                    queryParams.token = this.accessToken;
                }

                // Agregar auth_id solo si existe this.AuthID
                if (this.AuthID) {
                    queryParams.auth_id = this.AuthID;
                }
				this.$router.push({
					name: `${hasAssociation ? 'custom-' : ''}attachments`,
					query: queryParams
				});
			} else if (this.hasAttachments && (docCount > 0) && this.hasPendingSignerForm && this.hasSignerForm) {
				this.$router.push({
					name: `${hasAssociation ? 'custom-' : ''}forms`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});
			} else if (!this.hasAttachments && this.hasPendingSignerForm && this.hasSignerForm) {
				this.$router.push({
					name: `${hasAssociation ? 'custom-' : ''}forms`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});

			}  else if (this.hasAttachments && (docCount > 0)  && !this.hasPendingSignerForm && this.hasSignerForm) {
            // Construir dinámicamente la query sin incluir auth_id si es null
            let queryParams = { token: this.Token };

            // Solo agregar auth_id si tiene un valor válido
            if (this.AuthID) {
                queryParams.auth_id = this.AuthID;
            }

            this.$router.push({
                name: `${hasAssociation ? 'custom-' : ''}show-document`,
                params: {
                    association: this.$route.params.association, // Mantiene la asociación en la URL
                    id: this.requestId // Usa directamente el valor de SignRequestID
                },
                query: queryParams
            });

			} else if (!this.hasAttachments && !this.hasPendingSignerForm && !this.hasSignerForm) {
				let queryParams = { token: this.Token };

                // Solo agregar auth_id si existe
                if (this.AuthID) {
                    queryParams.auth_id = this.AuthID;
                }
                // Construir dinámicamente la query sin incluir auth_id si es null
                queryParams = { token: this.Token };

                // Solo agregar auth_id si tiene un valor válido
                if (this.AuthID) {
                    queryParams.auth_id = this.AuthID;
                }

                this.$router.push({
                    name: `${hasAssociation ? 'custom-' : ''}show-document`,
                    params: {
                        association: this.$route.params.association, // Mantiene la asociación en la URL
                        id: this.requestId // Usa directamente el valor de SignRequestID
                    },
                    query: queryParams
                });

			} else if (this.hasAttachments && !this.hasPendingSignerForm && !this.hasSignerForm) {
				this.$router.push({
					name: `${hasAssociation ? 'custom-' : ''}attachments`,
					query: {
						token: this.Token,
						auth_id: this.AuthID
					}
				});
			} else if (this.hasAttachments && !this.hasPendingSignerForm) {
            // Construir dinámicamente la query sin incluir auth_id si es null
            let queryParams = { token: this.Token };

            // Solo agregar auth_id si tiene un valor válido
            if (this.AuthID) {
                queryParams.auth_id = this.AuthID;
            }

            this.$router.push({
                name: `${this.hasAssociation ? 'custom-' : ''}show-document`,
                params: {
                    association: this.$route.params.association, // Mantiene la asociación en la URL
                    id: this.requestId // Usa directamente el valor de SignRequestID
                },
                query: queryParams
            });

			}


		},

//NUEVA FUNCIÓN LOADDOCUMENTDATA

		async loadDocumentData(RequestID, AuthenticationID) {
			if (RequestID) {
				this.loading = true;
				try {
					const response = await FormsService.getFormStatus(RequestID, this.Token, AuthenticationID);
					this.requestDetails = response.data;
					this.$store.commit('forms/setHasSignerForm', this.requestDetails.hasSignerForm);
					this.$store.commit('forms/setHasPendingSignerForm', this.requestDetails.hasPendingSignerForm);
					this.$store.commit('attachments/setHasAttachments', this.requestDetails.hasAttachments);
					await this.$store.dispatch('attachments/setTransactionApiToken', this.requestDetails.apiToken);

                // 🚀 Esperar a que Vuex reactive los cambios
                await this.$nextTick();

                // 🔄 Forzar actualización de la UI
                this.$forceUpdate();

					this.loading = false;
				} catch (error) {
					console.error('API Call Failed:', error);
					this.loading = false;
				}
			} else {
				console.log('No Request ID found');
			}
		},

		checkBatchProcessStatus() {
			if (
				this.totalSelectedDocs == this.successDocs + this.failDocs &&
				!this.invalidPin
			) {
				this.page = 1;
				this.resetProcess();
				this.loadDocumentList({});
				this.certificatePinCodeDialog = false;
				this.completeBatchProcess = true;
				this.resetSuccessCount();
			}
		},
		showAdvanceSearchDialog() {
			this.advanceSearchDialog = true;
		},
		submitFilters(filters) {
			this.searchKeyWord = "";
			this.docList = [];
			this.searchFilters = JSON.parse(JSON.stringify(filters));
			this.page = 1;
			this.loadDocumentList(JSON.parse(JSON.stringify(filters)));
			this.advanceSearchDialog = false;
			localStorage.setItem("reviewed_list", "");
		},

async signDocument() {
    console.log("📌 Iniciando signDocument...");

    // Guardar fecha y ubicación antes de abrir diálogos
//    this.submitDateAndLocation();
    this.openLocationDialog();
    console.log("Documentos seleccionados: " + this.selectedDocs);
    console.log("OTP: " + this.selectedDocs[0].otp);



    // 1️⃣ Verificar si hay un documento con firma PAD
    if (this.selectedDocs.some((o) => o.pad === true)) {
        console.log("🔹 Documento con firma PAD encontrado. Abriendo diálogo...");
        this.padStatus = true;
        this.padDialog = true;
        await this.$nextTick(); // Esperar a que Vue actualice el DOM
        console.log("⏳ Esperando cierre de padDialog...");
        await new Promise((resolve) => {
            const checkInterval = setInterval(() => {
                if (!this.padDialog) {
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 500);
        });
        console.log("✅ padDialog cerrado. Continuando...");
    }

    // 2️⃣ Verificar si hay documentos con OTP
    if (this.selectedDocs.some((o) => o.otp === true)) {
        console.log("🔹 Documento con OTP encontrado. Abriendo diálogo...");
        this.otpStatus = true;
        this.sendOtpCode();
        this.otpDialog = true;
        await this.$nextTick();
        console.log("⏳ Esperando cierre de otpDialog...");
        await new Promise((resolve) => {
            const checkInterval = setInterval(() => {
                if (!this.otpDialog) {
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 500);
        });
        console.log("✅ otpDialog cerrado. Continuando...");
    }

    // 3️⃣ Verificar si se necesita PIN manual
    if (this.selectedDocs.some((o) => o.type === "HUMAN")) {
        console.log("🔹 Documento HUMANO encontrado. Abriendo diálogo de PIN...");
        this.certificatePinCodeDialog = true;
        await this.$nextTick();
        console.log("⏳ Esperando cierre de certificatePinCodeDialog...");
        await new Promise((resolve) => {
            const checkInterval = setInterval(() => {
                if (!this.certificatePinCodeDialog) {
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 500);
        });
        console.log("✅ certificatePinCodeDialog cerrado. Continuando...");
    }

    // 4️⃣ Si no hay más diálogos que abrir, iniciar la firma
    await this.submitBatchOfDocuments();
},

		submitSignPad(signature) {
			console.log("submitSignPad");
			this.signaturePadImage = signature;
			this.padDialog = false;
/*   Simplificando la salida del PAD
			if(this.selectedDocs.find((o) => o.type === "ONETIMEREGISTERED" && o.otp === false)){
				console.log("onetimeregistered");
				this.lastRefresh = moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ");
				this.location = this.userLatitude && this.userLongitude
						? this.userLatitude + ", " + this.userLongitude
						: "",
				this.submitBatchOfDocuments();
			} if(this.selectedDocs.find((o) => o.type === "ONETIMEREGISTERED" && o.otp === true)){
				console.log("otp y onetimeregistered");
				this.lastRefresh = moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ");
				this.location = this.userLatitude && this.userLongitude
						? this.userLatitude + ", " + this.userLongitude
						: "",
                this.otpDialog = true;
				this.submitBatchOfDocuments();

			} else {
				this.certificatePinCodeDialog = true;
				this.padStatus = false;
				}     */
		},
        submitOtpCode(otpCode) {
          console.log("Recibido OTP:", otpCode);
          this.otpCode = otpCode;

          this.otpDialog = false; // 🔥 Cerrar el diálogo OTP

          this.$nextTick(() => {
            console.log("OTP cerrado, enviando documento...");
            this.submitBatchofDocuments(); // 💡 Ahora el flujo sigue correctamente
          });
        },
		submitCertificatePin(pinCode) {
			this.certificatePinCode = pinCode;
			this.certificatePinCodeDialog = false;
/*			this.submitBatchOfDocuments(); */
		},

        async submitBatchOfDocuments() {
            console.log("🚀 Ejecutando submitBatchOfDocuments con selectedDocs..." + this.selectedDocs.length );

            if (this.selectedDocs.length > 0) {
                this.totalSelectedDocs = this.selectedDocs.length;
                this.requestType=this.selectedDocs[0].type;
                this.invalidPin = false;
                this.error = false;
                this.batchSigning = true;
                this.successDocs = 0;
                this.failDocs = 0;
                this.authId=localStorage.getItem("auth_id");

                this.data = this.getSubmitData();
                console.log("Data: " + this.data);
                console.log("Request Type: " + this.requestType);
                if(this.requestType=="HUMAN")  // La firma móvil con certificado es la única que requiere token de usuario
                {
                    this.token = localStorage.getItem("auth_user_token");
                    }



                for (let index = 0; index < this.selectedDocs.length; index++) {
                    const doc = this.selectedDocs[index];
                    console.log(`📌 Firmando documento ${index + 1}/${this.totalSelectedDocs} con ID: ${doc.id}`);
                    if(this.requestType!="HUMAN") {
                        this.token=doc.apiToken;
                        }
                    // this.authId=localStorage.getItem("auth_id");
                    this.requestId=doc.id;
                    // this.requestType=doc.type;

                    try {
                        await this.signCertificateSignature(this.token, doc);
                        // sucessDocs se actualiza dentro de signCertificateSignature
                        console.log(`📌 Estado actual: Firmados ${this.successDocs + this.failDocs} de ${this.totalSelectedDocs}`);
                    } catch (error) {
                        console.error(`❌ Error al firmar documento ${doc.id}:`, error);
                        this.failDocs++; // ❌ Contar errores correctamente
                    }

                    // ✅ Verificar si todos los documentos han sido procesados antes de cerrar el diálogo

                }
                console.log("✅ Todos los documentos han sido procesados.");
                this.batchSigning = false;
            }
        },


        async signCertificateSignature(apiToken, list) {
            try {
                console.log("dentro de signCertificateSignature");
//                let data = this.getSubmitData();
                console.log("pad: " + list.pad);
                console.log("PIN: " + this.certificatePinCode);
                console.log("Location: " + this.location);
                console.log("Token: " + this.token);
                console.log("AuthId: " + this.authId);
                console.log("RequestId: " + this.requestId);
                console.log("RequestType: " + this.requestType);
                localStorage.setItem("access_token", apiToken);  //Esto liga con el Interceptor en http-common.js que usa este token
/*
                if (list.pad) {
                    data["signatureImage"] = this.signaturePadImage;
                }

                if (list.otp) {
                    data["otp"] = this.otpCode;
                }

                if (this.lastRefresh) {
                    data["lastRefresh"] = this.lastRefresh;
                }
                if (this.location && (this.requestType!="HUMAN")) {
                    data["location"] = this.location;
                }

                let signatureId = list.id;
                console.log("Data for Signature Service: " + signatureId, apiToken);

                const response = await SignaturesService.signCertificateSignaturesDocument(
                    apiToken,
                    this.data,
                    signatureId
                );

                console.log("Data for Signature Service: " + this.requestId, this.data); */

                let response = await SignaturesService.signDocument(this.requestId, this.data);

                if (response.status === 200) {
                    this.successDocs++;
                }
            } catch (error) {
                this.failDocs++;

                if (error.response?.status === 422) {
                    this.invalidPin = true;
                    this.error = true;
                    this.errorMsg =
                        error.response.data.description === "SYSTEM.PKCS11_PIN_INCORRECT"
                            ? this.$t("document_list.invalid_pin_code")
                            : this.$t("server_error");
                    this.loading = false;
                } else {
                    console.error(`❌ Error al firmar documento ${list.id}:`, error);
                }
            }
        },

		review() {
			this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}multiple-document-list-review` })
		},
		handleLinkClick(id,transactionId,status) {
            console.log("🔹 ID del documento:", id);
            console.log("🔹 ID de la transacción:", transactionId);
            this.requestId=id;
            this.accessToken=this.findTokenFromCheckList();
            console.log("Token preView: " + this.accessToken);
            localStorage.setItem("access_token", this.accessToken);

            // Verificar si id es undefined o null
            if (!id) {
                console.error("❌ ERROR: El ID del documento es inválido:", id);
                return;
            }

            // Verificar si transactionId es undefined o null
            if (!transactionId) {
                console.warn("⚠️ ADVERTENCIA: La transacción no tiene ID:", transactionId);
            }
            if (id) {
                localStorage.setItem('request_id', id);
//                this.SignRequestID = id;
				localStorage.setItem('transaction_id', transactionId);
                console.log(`Request ID guardado en localStorage: ${id}`);

                  // Redirigir con o sin asociación
                if (status === "SIGNED") {
                    this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}summary` });
                }
                else {
                    this.singleDoc();
                }
            } else {
                console.warn('ID no válido:', id);
            }
        },

        setParams(params) {
            this.searchFilterCount = Object.keys(this.searchFilters).filter(
                (x) => this.searchFilters[x] != undefined
            ).length;

            if (this.searchFilterCount > 0) {
                Array.prototype.push.apply(params, this.searchFilters);
            }

            if (this.searchKeyWord) {
                params["description"] = this.searchKeyWord;
            }

            if ( // Is redirect from another page
                this.prevRoute.name &&
                this.prevRoute.name == "document-list-review"
            ) {
                this.page = 1;
                this.prevRoute = "";
            }

            // Agregar parámetros de paginación y estado
            params["page"] = this.page;
            if(!this.list) params["status"] = this.pendingStatus;

            // Verificar si authCitizenId en localStorage es true
            const authCitizenId = localStorage.getItem("authCitizenId");
            if (authCitizenId && JSON.parse(authCitizenId) === true) {
                params["authId"] = this.authId;
            }
            console.log("PARAMS: " + this.page + "," + this.pendingStatus + "," + this.authID);
            return params;
        },

		resetRejectCount() {
			setTimeout(
				function () {
					this.rejectedDocCount = 0;
					localStorage.setItem("rejected_doc_count", this.rejectedDocCount);
				}.bind(this),
				4000
			);
		},
		resetSuccessCount() {
			setTimeout(
				function () {
					this.completeBatchProcess = false;
					this.successDocs = 0;
					this.failDocs = 0;
				}.bind(this),
				4000
			);
		},
		loadDocumentList(paramsList) {
			this.loading = true;
			this.authId=localStorage.getItem("auth_id");
			let params = this.setParams(paramsList);
            const apiToken = this.setApiToken();
            console.log("Token: " + apiToken);

			SignaturesService.getCertificateSignatureRequests(apiToken, params)
				.then((response) => {
					this.docList = this.docList.concat(
						response.data.signatureRequestsList
					);
					this.setReviewLocalData(params);
					this.page = response.data.pageNumber;
					this.totalPages = response.data.totalPages;
					this.numReqPending = response.data.numReqPending;

					this.loading = false;
					this.loadAllPagination();
					console.log("Request Type: " + this.requestType);
				})
				.catch(() => {
					this.loading = false;
				});
		},

		loadAllPagination() {
			if (this.totalPages > this.page) {
				const reviewParams = this.getReviewUrlParams();
				this.page = this.page + 1;
				this.loadDocumentList(reviewParams);
			} else {
				const reviewedList = this.checkReviewedDocumentList();
				if (reviewedList) {
					this.selectedDocs = reviewedList;
				} else {
					this.selectedDocs = this.docList;
				}
				this.rejectedDocCount = Number(this.getRejectedCount());
				this.resetRejectCount();
			}
		},
		getReviewUrlParams() {
			return JSON.parse(JSON.stringify(this.searchUrlParams));
		},
		checkReviewedDocumentList() {
			const reviewed =
				localStorage.getItem("reviewed_list") != ""
					? JSON.parse(localStorage.getItem("reviewed_list"))
					: "";
			return reviewed.length > 0 ? reviewed : "";
		},
		setReviewLocalData(params) {
			this.searchUrlParams = params;
		},
		loadSearchList() {
			this.searchFilters = {};
			this.docList = [];
			this.page = 1;
			this.loadDocumentList({});
			localStorage.setItem("reviewed_list", "");
		},
		getRejectedCount() {
			return localStorage.getItem("rejected_doc_count");
		},
		setRejectedCount(value) {
			return localStorage.setItem("rejected_doc_count", value);
		},
		checkSelectAll(x) {
			if (x.value) {
				this.selectedDocs = this.docList;
			} else {
				this.selectedDocs = [];
			}
		},
	},
	mounted() {
        this.list = localStorage.getItem("list");
        this.user = localStorage.getItem("user");
        this.authId = localStorage.getItem("auth_id");
		this.loadDocumentList({});
		this.moment = moment;
	},

	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.prevRoute = from;
		});
	},
};
</script>
